import {
  GET_ALL_BLOGS,
  GET_ALL_BLOGS_SUCCESS,
  GET_ALL_BLOGS_FAILED,
  SAVE_BLOG,
  SAVE_BLOG_SUCCESS,
  SAVE_BLOG_FAILED, 
} from "../actions";
  
export const getAllBlogWithoutLimit = (payload) => ({
  type: GET_ALL_BLOGS,
  payload: payload,
});

export const getBlogs = (payload) => ({
  type: GET_ALL_BLOGS,
  payload: payload,
});


export const getBlogsSuccess = (payload) => ({
  type: GET_ALL_BLOGS_SUCCESS,
  payload: payload,
});

export const getBlogFailed = (error) => ({
  type: GET_ALL_BLOGS_FAILED,
  payload: error,
});


export const saveBlog = (payload) => ({
  type: SAVE_BLOG,
  payload: payload,
});

export const saveBlogSuccess = (payload) => ({
  type: SAVE_BLOG_SUCCESS,
  payload: payload,
});

export const saveBlogFailed = (error) => ({
  type: SAVE_BLOG_FAILED,
  payload: error,
});