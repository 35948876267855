import {
  GET_CARRIERS,
  GET_CARRIERS_SUCCESS,
  GET_CARRIERS_FAILED,
  ADD_CARRIER,
  ADD_CARRIER_SUCCESS,
  ADD_CARRIER_FAILED,
  LOGOUT_ADMIN,
  DELETE_CARRIER_FAILED,
  DELETE_CARRIER,
  DELETE_CARRIER_SUCCESS,
  UPDATE_CARRIER_FAILED,
  UPDATE_CARRIER_SUCCESS,
  UPDATE_CARRIER,
} from "../actions";

const INIT_STATE = {
  carriers: [],
  loading: false,
  error: null,
  addCarrierSuccess: false,
  deleteCarrierSuccess: false,
  updateCarrierSuccess: false,
};

export default (state = INIT_STATE, action) => {
  let payload = action.payload;
  switch (action.type) {
    case GET_CARRIERS:
    case ADD_CARRIER:
    case DELETE_CARRIER:
    case UPDATE_CARRIER:
      return {
        ...state,
        loading: true,
        error: null,
        addCarrierSuccess: false,
        deleteCarrierSuccess: false,
        updateCarrierSuccess: false,
      };

    case GET_CARRIERS_SUCCESS:
      return { ...state, loading: false, error: null, carriers: payload };

    case ADD_CARRIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        addCarrierSuccess: true,
      };

    case DELETE_CARRIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        deleteCarrierSuccess: true,
      };

    case UPDATE_CARRIER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        updateCarrierSuccess: true,
      };

    case GET_CARRIERS_FAILED:
    case ADD_CARRIER_FAILED:
    case DELETE_CARRIER_FAILED:
    case UPDATE_CARRIER_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error_message,
      };

    case LOGOUT_ADMIN:
      return INIT_STATE;
    default:
      return state;
  }
};
