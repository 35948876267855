import {
  ADD_SUBSCRIPTION,
  LOGOUT_ADMIN,
  UPDATE_ADMIN,
  UPDATE_SUBSCRIPTION,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAILED,
  UPLOAD_IMAGE_SUCCESS,
} from "../actions";

const INIT_STATE = {
  image: "",
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  let payload = action.payload;
  switch (action.type) {
    case UPLOAD_IMAGE:
    case UPDATE_ADMIN:
    case ADD_SUBSCRIPTION:
    case UPDATE_SUBSCRIPTION:
      return { ...state, loading: true, error: null, image: "" };
    case UPLOAD_IMAGE_SUCCESS:
      return { ...state, loading: false, error: null, image: payload };
    case UPLOAD_IMAGE_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error_message,
      };
    case LOGOUT_ADMIN:
      return INIT_STATE;
    default:
      return state;
  }
};
