import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  LOGIN_ADMIN,
  REGISTER_ADMIN,
  LOGOUT_ADMIN,
  UPDATE_ADMIN,
  GET_ADMIN,
} from "../actions";

import {
  loginAdminSuccess,
  loginAdminFailed,
  registerAdminSuccess,
  registerAdminFailed,
  getAdminSuccess,
  getAdminFailed,
  updateAdminSuccess,
  updateAdminFailed,
} from "./actions";
import { API_ENDPOINT } from "../../constant";

var res ;

const loginWithEmailPasswordAsync = async (user) => {
  return new Promise((resolve, reject) => {  
    axios
      .post(`${API_ENDPOINT}/admin/login`, user)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
      return user;
  });
};

let users = JSON.parse(localStorage.getItem('user_id')) || [];
function* loginWithEmailPassword({ payload }) {
  const { history } = payload;
  try {
    const loginAdmin = yield call(loginWithEmailPasswordAsync, payload);
    if (loginAdmin.status === true && users) {
      yield put(loginAdminSuccess(loginAdmin.data));
      localStorage.setItem('user_id', JSON.stringify(loginAdmin))
      history.push('app/dashboard')
    }
  } catch (error) {
    console.log("error", error.response);
    yield put(loginAdminFailed(error.response.data));
    localStorage.clear(users)
  }
}

const registerWithEmailPasswordAsync = async (user) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_ENDPOINT}/user`, user)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* registerWithEmailPassword({ payload }) {
  const { history } = payload;
  try {
    const registerAdmin = yield call(
      registerWithEmailPasswordAsync,
      payload.user
    );
    if (registerAdmin.status === true) {
      yield put(registerAdminSuccess(registerAdmin.data));
      history.push("/");
    }
  } catch (error) {
    console.log("register error : ", error);
    yield put(registerAdminFailed(error.response.data));
  }
}

const logoutAsync = async (history) => {
  history.push("/login")
  localStorage.removeItem('user_id')
}

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync, history);
    localStorage.removeItem("user_id");
    window.location.reload()
  } catch (error) {}
}

const getAdminAsync = async (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_ENDPOINT}/admin/${payload.id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* getAdmin({ payload }) {
  try {
    const adminResponse = yield call(getAdminAsync, payload);
    if (adminResponse.status === true) {
      yield put(getAdminSuccess(adminResponse.data));
      console.log('inside getadmin')
    }
  } catch (error) {
    yield put(getAdminFailed(error.response.data));
  }
}

const updateAdminAsync = async (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_ENDPOINT}/admin`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* updateAdmin({ payload }) {
  try {
    const adminResponse = yield call(updateAdminAsync, payload);
    if (adminResponse.status === true) {
      yield put(updateAdminSuccess(adminResponse.data));
      console.log('inside updateadmin')
    }
  } catch (error) {
    yield put(updateAdminFailed(error.response.data));
  }
}

export function* watchRegisterAdmin() {
  yield takeEvery(REGISTER_ADMIN, registerWithEmailPassword);
}

export function* watchLoginAdmin() {
  yield takeEvery(LOGIN_ADMIN, loginWithEmailPassword);
}

export function* watchLogoutAdmin() {
  yield takeEvery(LOGOUT_ADMIN, logout);
}

export function* watchGetAdmin() {
  yield takeEvery(GET_ADMIN, getAdmin);
}

export function* watchUpdateAdmin() {
  yield takeEvery(UPDATE_ADMIN, updateAdmin);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginAdmin),
    fork(watchLogoutAdmin),
    fork(watchRegisterAdmin),
    fork(watchGetAdmin),
    fork(watchUpdateAdmin),
  ]);
}
