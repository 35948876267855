import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { UPLOAD_IMAGE } from "../actions";
import { API_ENDPOINT } from "../../constant";
import { uploadImageSuccess, uploadImageFailed } from "./actions";

const uploadImageAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_ENDPOINT}/upload`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* uploadImage({ payload }) {
  try {
    const imageResponse = yield call(uploadImageAsync, payload);
    if (imageResponse.status === true) {
      yield put(uploadImageSuccess(imageResponse.url));
    }
  } catch (error) {
    yield put(uploadImageFailed(error.response.data));
  }
}

export function* watchUploadImage() {
  console.log("called upload");
  yield takeEvery(UPLOAD_IMAGE, uploadImage);
}

export default function* rootSaga() {
  yield all([fork(watchUploadImage)]);
}
