import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { GET_TRANSACTIONS } from "../actions";
import { API_ENDPOINT } from "../../constant";
import { getTransactionSuccess, getTransactionFailed } from "./actions";

const getTransactionAsync = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_ENDPOINT}/transaction`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* getTransaction() {
  try {
    const getTransactionResponse = yield call(getTransactionAsync);
    if (getTransactionResponse.status === true) {
      yield put(getTransactionSuccess(getTransactionResponse.data));
    }
  } catch (error) {
    yield put(getTransactionFailed(error.response.data));
  }
}

export function* watchGetTransaction() {
  yield takeEvery(GET_TRANSACTIONS, getTransaction);
}

export default function* rootSaga() {
  yield all([fork(watchGetTransaction)]);
}
