/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGIN = "LOGIN";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILED = "LOGIN_ADMIN_FAILED";

export const REGISTER_ADMIN = "REGISTER_ADMIN";
export const REGISTER_ADMIN_SUCCESS = "REGISTER_ADMIN_SUCCESS";
export const REGISTER_ADMIN_FAILED = "REGISTER_ADMIN_FAILED";

export const LOGOUT_ADMIN = "LOGOUT_ADMIN";

export const GET_ADMIN = "GET_ADMIN";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILED = "GET_ADMIN_FAILED";

export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILED = "UPDATE_ADMIN_FAILED";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* DASHBOARD */
export const GET_DASHBOARD_COUNT = "GET_DASHBOARD_COUNT";
export const GET_DASHBOARD_COUNT_SUCCESS = "GET_DASHBOARD_COUNT_SUCCESS";
export const GET_DASHBOARD_COUNT_FAILED = "GET_DASHBOARD_COUNT_FAILED";

export const GET_ORDER_GRAPH = "GET_ORDER_GRAPH";
export const GET_ORDER_GRAPH_SUCCESS = "GET_ORDER_GRAPH_SUCCESS";
export const GET_ORDER_GRAPH_FAILED = "GET_ORDER_GRAPH_FAILED";

/* USER MANAGEMENT */
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";

export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAILED = "UPDATE_PAYMENT_FAILED";

/* SNN MANAGEMENT */
export const GET_SNN = "GET_SNN";
export const GET_SNN_SUCCESS = "GET_SNN_SUCCESS";
export const GET_SNN_FAILED = "GET_SNN_FAILED";

export const ADD_SNN = "ADD_SNN";
export const ADD_SNN_SUCCESS = "ADD_SNN_SUCCESS";
export const ADD_SNN_FAILED = "ADD_SNN_FAILED";

/* SUBSCRIPTION MANAGEMENT */
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAILED = "GET_SUBSCRIPTIONS_FAILED";

export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS";
export const ADD_SUBSCRIPTION_FAILED = "ADD_SUBSCRIPTION_FAILED";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAILED = "UPDATE_SUBSCRIPTION_FAILED";

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_FAILED = "DELETE_SUBSCRIPTION_FAILED";

/* COUPONS MANAGEMENT */
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAILED = "GET_COUPONS_FAILED";

export const ADD_COUPON = "ADD_COUPON";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAILED = "ADD_COUPON_FAILED";

export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAILED = "DELETE_COUPON_FAILED";

export const UPDATE_COUPON = "UPDATE_COUPON";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAILED = "UPDATE_COUPON_FAILED";

/* CARRIER MANAGEMENT */
export const GET_CARRIERS = "GET_CARRIERS";
export const GET_CARRIERS_SUCCESS = "GET_CARRIERS_SUCCESS";
export const GET_CARRIERS_FAILED = "GET_CARRIERS_FAILED";

export const ADD_CARRIER = "ADD_CARRIER";
export const ADD_CARRIER_SUCCESS = "ADD_CARRIER_SUCCESS";
export const ADD_CARRIER_FAILED = "ADD_CARRIER_FAILED";

export const DELETE_CARRIER = "DELETE_CARRIER";
export const DELETE_CARRIER_SUCCESS = "DELETE_CARRIER_SUCCESS";
export const DELETE_CARRIER_FAILED = "DELETE_CARRIER_FAILED";

export const UPDATE_CARRIER = "UPDATE_CARRIER";
export const UPDATE_CARRIER_SUCCESS = "UPDATE_CARRIER_SUCCESS";
export const UPDATE_CARRIER_FAILED = "UPDATE_CARRIER_FAILED";

/* TRANSACTION */
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILED = "GET_TRANSACTIONS_FAILED";

/* IMAGE UPLOAD */
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILED = "UPLOAD_IMAGE_FAILED";

export * from "./menu/actions";
export * from "./settings/actions";

// BLOG
export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAILED = "GET_ALL_BLOGS_FAILED";

export const SAVE_BLOG = "SAVE_BLOG";
export const SAVE_BLOG_SUCCESS = "SAVE_BLOG_SUCCESS";
export const SAVE_BLOG_FAILED = "SAVE_BLOG_FAILED";
