import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_FAILED,
  GET_TRANSACTIONS_SUCCESS,
} from "../actions";

export const getTransaction = () => ({
  type: GET_TRANSACTIONS,
});

export const getTransactionSuccess = (payload) => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload,
});

export const getTransactionFailed = (payload) => ({
  type: GET_TRANSACTIONS_FAILED,
  payload,
});
