import {
  GET_COUPONS,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAILED,
  ADD_COUPON,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FAILED,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILED,
  UPDATE_COUPON,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAILED,
} from "../actions";

// Get coupon actions
export const getCoupons = () => ({
  type: GET_COUPONS,
});

export const getCouponsSuccess = (payload) => ({
  type: GET_COUPONS_SUCCESS,
  payload,
});

export const getCouponsFailed = (payload) => ({
  type: GET_COUPONS_FAILED,
  payload,
});

// Add coupon actions
export const addCoupon = (payload) => ({
  type: ADD_COUPON,
  payload,
});

export const addCouponSuccess = (payload) => ({
  type: ADD_COUPON_SUCCESS,
  payload,
});

export const addCouponFailed = (payload) => ({
  type: ADD_COUPON_FAILED,
  payload,
});

// Delete coupon actions
export const deleteCoupon = (payload) => ({
  type: DELETE_COUPON,
  payload,
});

export const deleteCouponSuccess = (payload) => ({
  type: DELETE_COUPON_SUCCESS,
  payload,
});

export const deleteCouponFailed = (payload) => ({
  type: DELETE_COUPON_FAILED,
  payload,
});

// Update coupon actions
export const updateCoupon = (payload) => ({
  type: UPDATE_COUPON,
  payload,
});

export const updateCouponSuccess = (payload) => ({
  type: UPDATE_COUPON_SUCCESS,
  payload,
});

export const updateCouponFailed = (payload) => ({
  type: UPDATE_COUPON_FAILED,
  payload,
});
