import {
  LOGIN_ADMIN,
  LOGIN_ADMIN_SUCCESS,
  LOGIN_ADMIN_FAILED,
  LOGOUT_ADMIN,
  REGISTER_ADMIN,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_FAILED,
  GET_ADMIN,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILED,
  UPDATE_ADMIN,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_ADMIN_FAILED,
} from "../actions";

export const loginAdmin = (payload) => ({
  type: LOGIN_ADMIN,
  payload
});


export const loginAdminSuccess = (user) => ({
  type: LOGIN_ADMIN_SUCCESS,
  payload: user,
});

export const loginAdminFailed = (payload) => ({
  type: LOGIN_ADMIN_FAILED,
  payload: payload,
});

export const registerAdmin = (user, history) => ({
  type: REGISTER_ADMIN,
  payload: { user, history },
});

export const registerAdminSuccess = (user) => ({
  type: REGISTER_ADMIN_SUCCESS,
  payload: user,
});

export const registerAdminFailed = (payload) => ({
  type: REGISTER_ADMIN_FAILED,
  payload: payload,
});

export const logoutAdmin = (history) => ({
  type: LOGOUT_ADMIN,
  payload: { history },
});

// get admin
export const getAdmin = (payload) => ({
  type: GET_ADMIN,
  payload,
});

export const getAdminSuccess = (payload) => ({
  type: GET_ADMIN_SUCCESS,
  payload,
});

export const getAdminFailed = (payload) => ({
  type: GET_ADMIN_FAILED,
  payload: payload,
});

// update admin
export const updateAdmin = (payload) => ({
  type: UPDATE_ADMIN,
  payload,
});

export const updateAdminSuccess = (payload) => ({
  type: UPDATE_ADMIN_SUCCESS,
  payload,
});

export const updateAdminFailed = (payload) => ({
  type: UPDATE_ADMIN_FAILED,
  payload: payload,
});
