import {
  LOGIN_ADMIN,
  LOGIN_ADMIN_SUCCESS,
  LOGIN_ADMIN_FAILED,
  REGISTER_ADMIN,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_FAILED,
  LOGOUT_ADMIN,
  GET_ADMIN,
  UPDATE_ADMIN,
  GET_ADMIN_SUCCESS,
  UPDATE_ADMIN_SUCCESS,
  GET_ADMIN_FAILED,
  UPDATE_ADMIN_FAILED,
} from "../actions";

const INIT_STATE = {
  user: {},
  loading: false,
  error: null,
  updated: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_ADMIN:
    case REGISTER_ADMIN:
    case GET_ADMIN:
    case UPDATE_ADMIN:
      return { ...state, loading: true, error: null, updated: false };

    case LOGIN_ADMIN_SUCCESS:
      return { ...state, loading: false, user: action.payload, error: null };
    case REGISTER_ADMIN_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case GET_ADMIN_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case UPDATE_ADMIN_SUCCESS:
      return { ...state, loading: false, user: action.payload, updated: true };

    case LOGIN_ADMIN_FAILED:
    case REGISTER_ADMIN_FAILED:
    case GET_ADMIN_FAILED:
    case UPDATE_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error_message,
        updated: false,
      };

    case LOGOUT_ADMIN:
      return INIT_STATE;

    default:
      return state;
  }
};
