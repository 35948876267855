import {
  GET_COUPONS,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAILED,
  ADD_COUPON,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_FAILED,
  LOGOUT_ADMIN,
  DELETE_COUPON_FAILED,
  DELETE_COUPON,
  DELETE_COUPON_SUCCESS,
  UPDATE_COUPON_FAILED,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON,
} from "../actions";

const INIT_STATE = {
  coupons: [],
  loading: false,
  error: null,
  addCouponSuccess: false,
  deleteCouponSuccess: false,
  updateCouponSuccess: false,
};

export default (state = INIT_STATE, action) => {
  let payload = action.payload;
  switch (action.type) {
    case GET_COUPONS:
    case ADD_COUPON:
    case DELETE_COUPON:
    case UPDATE_COUPON:
      return {
        ...state,
        loading: true,
        error: null,
        addCouponSuccess: false,
        deleteCouponSuccess: false,
        updateCouponSuccess: false,
      };

    case GET_COUPONS_SUCCESS:
      return { ...state, loading: false, error: null, coupons: payload };

    case ADD_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        addCouponSuccess: true,
      };

    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        deleteCouponSuccess: true,
      };

    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        updateCouponSuccess: true,
      };

    case GET_COUPONS_FAILED:
    case ADD_COUPON_FAILED:
    case DELETE_COUPON_FAILED:
    case UPDATE_COUPON_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error_message,
      };

    case LOGOUT_ADMIN:
      return INIT_STATE;
    default:
      return state;
  }
};
