import {
  GET_SNN,
  GET_SNN_SUCCESS,
  GET_SNN_FAILED,
  ADD_SNN,
  ADD_SNN_SUCCESS,
  ADD_SNN_FAILED,
} from "../actions";

export const getSnn = () => ({
  type: GET_SNN,
});

export const getSnnSuccess = (payload) => ({
  type: GET_SNN_SUCCESS,
  payload,
});

export const getSnnFailed = (payload) => ({
  type: GET_SNN_FAILED,
  payload,
});

export const addSnn = (payload) => ({
  type: ADD_SNN,
  payload,
});

export const addSnnSuccess = (payload) => ({
  type: ADD_SNN_SUCCESS,
  payload,
});

export const addSnnFailed = (payload) => ({
  type: ADD_SNN_FAILED,
  payload,
});
