import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  GET_SUBSCRIPTIONS,
  ADD_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
} from "../actions";
import { API_ENDPOINT } from "../../constant";
import {
  getSubscriptionsSuccess,
  getSubscriptionsFailed,
  addSubscriptionSuccess,
  addSubscriptionFailed,
  updateSubscriptionSuccess,
  updateSubscriptionFailed,
  deleteSubscriptionSuccess,
  deleteSubscriptionFailed,
} from "./actions";

const getSubscriptionsAsync = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_ENDPOINT}/subscription`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const addSubscriptionAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_ENDPOINT}/subscription`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const updateSubscriptionAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_ENDPOINT}/subscription/${payload.id}`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const deleteSubscriptionAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_ENDPOINT}/subscription?id=${payload.id}`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* getSubscriptions() {
  try {
    const getSubscriptionsResponse = yield call(getSubscriptionsAsync);
    if (getSubscriptionsResponse.status === true) {
      yield put(getSubscriptionsSuccess(getSubscriptionsResponse.data));
    }
  } catch (error) {
    yield put(getSubscriptionsFailed(error.response.data));
  }
}

function* addSubscription({ payload }) {
  try {
    const addSubscriptionResponse = yield call(addSubscriptionAsync, payload);
    if (addSubscriptionResponse.status === true) {
      yield put(addSubscriptionSuccess(addSubscriptionResponse.data));
    }
  } catch (error) {
    yield put(addSubscriptionFailed(error.response.data));
  }
}

function* updateSubscription({ payload }) {
  try {
    const updateSubscriptionResponse = yield call(
      updateSubscriptionAsync,
      payload
    );
    if (updateSubscriptionResponse.status === true) {
      yield put(updateSubscriptionSuccess(updateSubscriptionResponse.data));
    }
  } catch (error) {
    yield put(updateSubscriptionFailed(error.response.data));
  }
}

function* deleteSubscription({ payload }) {
  try {
    const deleteSubscriptionResponse = yield call(
      deleteSubscriptionAsync,
      payload
    );
    if (deleteSubscriptionResponse.status === true) {
      yield put(deleteSubscriptionSuccess(deleteSubscriptionResponse.data));
    }
  } catch (error) {
    yield put(deleteSubscriptionFailed(error.response.data));
  }
}

export function* watchGetSubscriptions() {
  yield takeEvery(GET_SUBSCRIPTIONS, getSubscriptions);
}

export function* watchAddSubscription() {
  yield takeEvery(ADD_SUBSCRIPTION, addSubscription);
}

export function* watchUpdateSubscription() {
  yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription);
}

export function* watchDeleteSubscription() {
  yield takeEvery(DELETE_SUBSCRIPTION, deleteSubscription);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSubscriptions),
    fork(watchAddSubscription),
    fork(watchUpdateSubscription),
    fork(watchDeleteSubscription),
  ]);
}
