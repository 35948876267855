import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  LOGOUT_ADMIN,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_FAILED,
  UPDATE_PAYMENT_SUCCESS,
} from "../actions";

const INIT_STATE = {
  users: [],
  loading: false,
  error: null,
  paymentUpdated: false,
};

export default (state = INIT_STATE, action) => {
  let payload = action.payload;
  switch (action.type) {
    case GET_USERS:
    case UPDATE_PAYMENT:
      return { ...state, loading: true, error: null, paymentUpdated: false };

    case GET_USERS_SUCCESS:
      return { ...state, loading: false, error: null, users: payload };

    case UPDATE_PAYMENT_SUCCESS:
      return { ...state, loading: false, error: null, paymentUpdated: true };

    case GET_USERS_FAILED:
    case UPDATE_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error_message,
        paymentUpdated: false,
      };
    case LOGOUT_ADMIN:
      return INIT_STATE;
    default:
      return state;
  }
};
