import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from "redux-saga";
import { persistStore } from 'redux-persist';
import reducers from './reducers';
import sagas from "./sagas";

const { persistReducer } = require('redux-persist');
const storage = require('redux-persist/lib/storage').default;
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

// export default(initialState) => {


    const persistConfig = {
        key: 'primary',
        storage
      };

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    export const store = createStore(
        persistReducer(persistConfig, reducers),
        composeEnhancers(applyMiddleware(...middlewares)),
    );

    export const persistor = persistStore(store)

    sagaMiddleware.run(sagas);

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    // return {store, persistor};
// }
