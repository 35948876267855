import {
  GET_SNN,
  GET_SNN_SUCCESS,
  GET_SNN_FAILED,
  ADD_SNN,
  ADD_SNN_SUCCESS,
  ADD_SNN_FAILED,
  LOGOUT_ADMIN,
} from "../actions";

const INIT_STATE = {
  snn: [],
  loading: false,
  error: null,
  addSnnSuccess: false,
};

export default (state = INIT_STATE, action) => {
  let payload = action.payload;
  switch (action.type) {
    case GET_SNN:
      return { ...state, loading: true, error: null, addSnnSuccess: false };
    case GET_SNN_SUCCESS:
      return { ...state, loading: false, error: null, snn: payload };

    case ADD_SNN:
      return { ...state, loading: true, error: null };
    case ADD_SNN_SUCCESS:
      return { ...state, loading: false, error: null, addSnnSuccess: true };

    case GET_SNN_FAILED:
    case ADD_SNN_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error_message,
      };
    case LOGOUT_ADMIN:
      return INIT_STATE;
    default:
      return state;
  }
};
