import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { GET_USERS, UPDATE_PAYMENT } from "../actions";
import { API_ENDPOINT } from "../../constant";
import {
  getUsersSuccess,
  getUsersFailed,
  updatePaymentSuccess,
  updatePaymentFailed,
} from "./actions";

const getUserAsync = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_ENDPOINT}/admin/users`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const updatePaymentAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_ENDPOINT}/payment`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* getusers() {
  try {
    const getUsersResponse = yield call(getUserAsync);
    if (getUsersResponse.status === true) {
      yield put(getUsersSuccess(getUsersResponse.data));
    }
  } catch (error) {
    yield put(getUsersFailed(error.response.data));
  }
}

function* updatePayment({ payload }) {
  try {
    const updatePaymentResponse = yield call(updatePaymentAsync, payload);
    if (updatePaymentResponse.status === true) {
      yield put(updatePaymentSuccess(updatePaymentResponse.data));
    }
  } catch (error) {
    yield put(updatePaymentFailed(error.response.data));
  }
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getusers);
}

export function* watchUpdatePayment() {
  yield takeEvery(UPDATE_PAYMENT, updatePayment);
}

export default function* rootSaga() {
  yield all([fork(watchGetUsers), fork(watchUpdatePayment)]);
}
