import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAILED,
} from "../actions";

export const getUsers = () => ({
  type: GET_USERS,
});

export const getUsersSuccess = (payload) => ({
  type: GET_USERS_SUCCESS,
  payload,
});

export const getUsersFailed = (payload) => ({
  type: GET_USERS_FAILED,
  payload,
});

export const updatePayment = (payload) => ({
  type: UPDATE_PAYMENT,
  payload,
});

export const updatePaymentSuccess = (payload) => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payload,
});

export const updatePaymentFailed = (payload) => ({
  type: UPDATE_PAYMENT_FAILED,
  payload,
});
