import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { GET_SNN, ADD_SNN } from "../actions";
import { API_ENDPOINT } from "../../constant";
import {
  getSnnSuccess,
  getSnnFailed,
  addSnnSuccess,
  addSnnFailed,
} from "./actions";

const getSnnAsync = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_ENDPOINT}/admin/snn`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const addSnnAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_ENDPOINT}/admin/snn`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* getSnn() {
  try {
    const getSnnResponse = yield call(getSnnAsync);
    if (getSnnResponse.status === true) {
      yield put(getSnnSuccess(getSnnResponse.data));
    }
  } catch (error) {
    yield put(getSnnFailed(error.response.data));
  }
}

function* addSnn({ payload }) {
  try {
    const addSnnResponse = yield call(addSnnAsync, payload);
    if (addSnnResponse.status === true) {
      yield put(addSnnSuccess(addSnnResponse.data));
    }
  } catch (error) {
    yield put(addSnnFailed(error.response.data));
  }
}

export function* watchGetSnn() {
  yield takeEvery(GET_SNN, getSnn);
}

export function* watchAddSnn() {
  yield takeEvery(ADD_SNN, addSnn);
}

export default function* rootSaga() {
  yield all([fork(watchGetSnn), fork(watchAddSnn)]);
}
