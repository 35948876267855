import {
  GET_ALL_BLOGS,
  GET_ALL_BLOGS_SUCCESS,
  GET_ALL_BLOGS_FAILED,
  SAVE_BLOG,
  SAVE_BLOG_SUCCESS,
  SAVE_BLOG_FAILED,
} from "../actions";
  
const INIT_STATE = {
  blog: [],
  pageCount:null,
  loading: false,
  error: null,
  deleted: false,
  customerOrder: [],
  isLimitReached: false,
};
  
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_BLOGS:

      return {
        ...state,
        loading: true,
        error: null,
        deleted: false,
        isLimitReached: false,
      };

    case GET_ALL_BLOGS_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        loading: false,
        blog: action.payload.blogs,
        pageCount: action.payload.count,
        error: null,
        deleted: false,
      };

    case GET_ALL_BLOGS_FAILED:

      return { ...state, loading: false, error: action.payload };

    case SAVE_BLOG:
      return {
        ...state,
        loading: true,
        error: null,
        deleted: false,
        isLimitReached: false,
      };
    case SAVE_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: action.payload,
        error: null,
        deleted: false,
        isLimitReached: false,
      };
    case SAVE_BLOG_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLimitReached: action.payload.isLimitReached,
      };


    default:
      return state;
  }
};
  