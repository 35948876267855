import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { API_ENDPOINT } from "../../constant";
import { GET_ALL_BLOGS, SAVE_BLOG } from "../actions";
import {
  getBlogsSuccess,
  getBlogFailed,
  saveBlogSuccess,
  saveBlogFailed,
} from "./actions";

const getBlogsAsync = (payload) => {
  return new Promise((resolve, reject) => {
  axios.get(`${API_ENDPOINT}/blogs?page=${payload.current_page}&limit=${payload.limit}`, {
      headers: { authorization: `${payload.user}:${payload.token}` },
    })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error));
  });
};

function* getBlogs({ payload }) {
  try {
    const blogList = yield call(getBlogsAsync, payload);
    if (blogList) {
      yield put(getBlogsSuccess(blogList));
      console.log(blogList);
    }
  } catch (error) {
    console.log('error', error)
    yield put(getBlogFailed(error.response));
  }
}

const saveBlogAsync = (payload) => {
  return new Promise((resolve, reject) => { 
    let data = new FormData();
    data.append('title', payload.title)
    data.append('description', payload.description)
    data.append('image', payload.image)
    axios
      .post(`${API_ENDPOINT}/blogs`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* saveBlog({ payload }) {
  try {
    const blog = yield call(saveBlogAsync, payload);
    if (blog) {
      yield put(saveBlogSuccess(blog));
      window.location.reload();
    }
  } catch (error) {
    yield put(saveBlogFailed(error.response));
  }
}

export function* watchGetBlogs() {
  yield takeEvery(GET_ALL_BLOGS, getBlogs);
}

export function* watchSaveBlog() {
  yield takeEvery(SAVE_BLOG, saveBlog);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetBlogs),
    fork(watchSaveBlog),
  ]);
}
