import {
  GET_CARRIERS,
  GET_CARRIERS_SUCCESS,
  GET_CARRIERS_FAILED,
  ADD_CARRIER,
  ADD_CARRIER_SUCCESS,
  ADD_CARRIER_FAILED,
  DELETE_CARRIER,
  DELETE_CARRIER_SUCCESS,
  DELETE_CARRIER_FAILED,
  UPDATE_CARRIER,
  UPDATE_CARRIER_SUCCESS,
  UPDATE_CARRIER_FAILED,
} from "../actions";

// Get coupon actions
export const getCarriers = () => ({
  type: GET_CARRIERS,
});

export const getCarriersSuccess = (payload) => ({
  type: GET_CARRIERS_SUCCESS,
  payload,
});

export const getCarriersFailed = (payload) => ({
  type: GET_CARRIERS_FAILED,
  payload,
});

// Add coupon actions
export const addCarrier = (payload) => ({
  type: ADD_CARRIER,
  payload,
});

export const addCarrierSuccess = (payload) => ({
  type: ADD_CARRIER_SUCCESS,
  payload,
});

export const addCarrierFailed = (payload) => ({
  type: ADD_CARRIER_FAILED,
  payload,
});

// Delete coupon actions
export const deleteCarrier = (payload) => ({
  type: DELETE_CARRIER,
  payload,
});

export const deleteCarrierSuccess = (payload) => ({
  type: DELETE_CARRIER_SUCCESS,
  payload,
});

export const deleteCarrierFailed = (payload) => ({
  type: DELETE_CARRIER_FAILED,
  payload,
});

// Update coupon actions
export const updateCarrier = (payload) => ({
  type: UPDATE_CARRIER,
  payload,
});

export const updateCarrierSuccess = (payload) => ({
  type: UPDATE_CARRIER_SUCCESS,
  payload,
});

export const updateCarrierFailed = (payload) => ({
  type: UPDATE_CARRIER_FAILED,
  payload,
});
