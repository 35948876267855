import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILED,
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAILED,
  LOGOUT_ADMIN,
  UPDATE_SUBSCRIPTION_FAILED,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAILED,
} from "../actions";

const INIT_STATE = {
  subscriptions: [],
  loading: false,
  error: null,
  addSubscriptionSuccess: false,
  updateSubscriptionSuccess: false,
  deleteSubscriptionSuccess: false,
};

export default (state = INIT_STATE, action) => {
  let payload = action.payload;
  switch (action.type) {
    case GET_SUBSCRIPTIONS:
    case ADD_SUBSCRIPTION:
    case UPDATE_SUBSCRIPTION:
    case DELETE_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
        error: null,
        addSubscriptionSuccess: false,
        updateSubscriptionSuccess: false,
        deleteSubscriptionSuccess: false,
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return { ...state, loading: false, error: null, subscriptions: payload };

    case ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        addSubscriptionSuccess: true,
      };

    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        updateSubscriptionSuccess: true,
      };

    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        deleteSubscriptionSuccess: true,
      };

    case GET_SUBSCRIPTIONS_FAILED:
    case ADD_SUBSCRIPTION_FAILED:
    case UPDATE_SUBSCRIPTION_FAILED:
    case DELETE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error_message,
      };
    case LOGOUT_ADMIN:
      return INIT_STATE;
    default:
      return state;
  }
};
