import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAILED,
  ADD_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAILED,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILED,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAILED,
} from "../actions";

// Get subscription actions
export const getSubscriptions = () => ({
  type: GET_SUBSCRIPTIONS,
});

export const getSubscriptionsSuccess = (payload) => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  payload,
});

export const getSubscriptionsFailed = (payload) => ({
  type: GET_SUBSCRIPTIONS_FAILED,
  payload,
});

// Add Subscription actions
export const addSubscription = (payload) => ({
  type: ADD_SUBSCRIPTION,
  payload,
});

export const addSubscriptionSuccess = (payload) => ({
  type: ADD_SUBSCRIPTION_SUCCESS,
  payload,
});

export const addSubscriptionFailed = (payload) => ({
  type: ADD_SUBSCRIPTION_FAILED,
  payload,
});

// Update Subscription actions
export const updateSubscription = (payload) => ({
  type: UPDATE_SUBSCRIPTION,
  payload,
});

export const updateSubscriptionSuccess = (payload) => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const updateSubscriptionFailed = (payload) => ({
  type: UPDATE_SUBSCRIPTION_FAILED,
  payload,
});

// Delete Subscription actions
export const deleteSubscription = (payload) => ({
  type: DELETE_SUBSCRIPTION,
  payload,
});

export const deleteSubscriptionSuccess = (payload) => ({
  type: DELETE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const deleteSubscriptionFailed = (payload) => ({
  type: DELETE_SUBSCRIPTION_FAILED,
  payload,
});
