import {
  GET_DASHBOARD_COUNT,
  GET_DASHBOARD_COUNT_SUCCESS,
  GET_DASHBOARD_COUNT_FAILED,
  GET_ORDER_GRAPH,
  GET_ORDER_GRAPH_SUCCESS,
  GET_ORDER_GRAPH_FAILED,
  LOGOUT_ADMIN,
} from "../actions";

const INIT_STATE = {
  dashboardCount: {},
  orderGraph: [],
  loading: false,
  error: null,
};

export default (state = INIT_STATE, action) => {
  let payload = action.payload;
  switch (action.type) {
    case GET_ORDER_GRAPH:
      return { ...state, loading: true, error: null };
    case GET_ORDER_GRAPH_SUCCESS:
      return { ...state, loading: false, error: null, orderGraph: payload };
    case GET_ORDER_GRAPH_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error_message,
      };

    case GET_DASHBOARD_COUNT:
      return { ...state, loading: true, error: null };
    case GET_DASHBOARD_COUNT_SUCCESS:
      return { ...state, loading: false, error: null, dashboardCount: payload };
    case GET_DASHBOARD_COUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error_message,
      };

    case LOGOUT_ADMIN:
      return INIT_STATE;
    default:
      return state;
  }
};
