import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { GET_DASHBOARD_COUNT, GET_ORDER_GRAPH } from "../actions";
import { API_ENDPOINT } from "../../constant";
import {
  getOrderGraphSuccess,
  getOrderGraphFailed,
  getDashboardCountSuccess,
  getDashboardCountFailed,
} from "./actions";

const getOrderGraphAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_ENDPOINT}/admin/ordergraph`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const getDashboardCountAsync = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_ENDPOINT}/admin/dashboardCount`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* getOrderGraph({ payload }) {
  try {
    const getOrderGraphResponse = yield call(getOrderGraphAsync, payload);
    if (getOrderGraphResponse.status === true) {
      yield put(getOrderGraphSuccess(getOrderGraphResponse.data));
    }
  } catch (error) {
    yield put(getOrderGraphFailed(error.response.data));
  }
}

function* getDashboardCount() {
  try {
    const getDashboardCountResponse = yield call(getDashboardCountAsync);
    if (getDashboardCountResponse.status === true) {
      yield put(getDashboardCountSuccess(getDashboardCountResponse.data));
    }
  } catch (error) {
    yield put(getDashboardCountFailed(error.response.data));
  }
}

export function* watchGetOrderGraph() {
  yield takeEvery(GET_ORDER_GRAPH, getOrderGraph);
}

export function* watchGetDashboardCount() {
  yield takeEvery(GET_DASHBOARD_COUNT, getDashboardCount);
}

export default function* rootSaga() {
  yield all([fork(watchGetOrderGraph), fork(watchGetDashboardCount)]);
}
