import {
  GET_ORDER_GRAPH,
  GET_ORDER_GRAPH_SUCCESS,
  GET_ORDER_GRAPH_FAILED,
  GET_DASHBOARD_COUNT,
  GET_DASHBOARD_COUNT_SUCCESS,
  GET_DASHBOARD_COUNT_FAILED,
} from "../actions";

export const getOrderGraph = (payload) => ({
  type: GET_ORDER_GRAPH,
  payload,
});

export const getOrderGraphSuccess = (payload) => ({
  type: GET_ORDER_GRAPH_SUCCESS,
  payload,
});

export const getOrderGraphFailed = (payload) => ({
  type: GET_ORDER_GRAPH_FAILED,
  payload,
});

export const getDashboardCount = () => ({
  type: GET_DASHBOARD_COUNT,
});

export const getDashboardCountSuccess = (payload) => ({
  type: GET_DASHBOARD_COUNT_SUCCESS,
  payload,
});

export const getDashboardCountFailed = (payload) => ({
  type: GET_DASHBOARD_COUNT_FAILED,
  payload,
});
