import { combineReducers } from "redux";
import authAdmin from "./auth/reducer";
import menuReducer from "./menu/reducer";
import settingsReducer from "./settings/reducer";
import userReducer from "./usermanagement/reducer";
import dashboardReducer from "./dashboard/reducer";
import snnReducer from "./snn/reducer";
import subscriptionReducer from "./subscription/reducer";
import couponReducer from "./coupon/reducer";
import transactionReducer from "./transactions/reducer";
import commonReducer from "./common/reducer";
import carrierReducer from "./carrier/reducer";
import blogReducer from "./blog/reducer";

const reducers = combineReducers({
  authAdmin,
  menuReducer,
  settingsReducer,
  userReducer,
  dashboardReducer,
  snnReducer,
  subscriptionReducer,
  couponReducer,
  transactionReducer,
  commonReducer,
  carrierReducer,
  blogReducer
});

export default reducers;
