import {
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_FAILED,
  UPLOAD_IMAGE_SUCCESS,
} from "../actions";

export const uploadImage = (payload) => ({
  type: UPLOAD_IMAGE,
  payload,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload,
});

export const uploadImageFailed = (payload) => ({
  type: UPLOAD_IMAGE_FAILED,
  payload,
});
