import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  GET_COUPONS,
  ADD_COUPON,
  DELETE_COUPON,
  UPDATE_COUPON,
} from "../actions";
import { API_ENDPOINT } from "../../constant";
import {
  getCouponsSuccess,
  getCouponsFailed,
  addCouponSuccess,
  addCouponFailed,
  deleteCouponSuccess,
  deleteCouponFailed,
  updateCouponSuccess,
  updateCouponFailed,
} from "./actions";

const getCouponsAsync = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_ENDPOINT}/coupon`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const addCouponAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_ENDPOINT}/coupon`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const deleteCouponAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_ENDPOINT}/coupon?id=${payload.id}`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const updateCouponAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_ENDPOINT}/coupon`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* getCoupons() {
  try {
    const getCouponsResponse = yield call(getCouponsAsync);
    if (getCouponsResponse.status === true) {
      yield put(getCouponsSuccess(getCouponsResponse.data));
    }
  } catch (error) {
    yield put(getCouponsFailed(error.response.data));
  }
}

function* addCoupon({ payload }) {
  try {
    const addCouponResponse = yield call(addCouponAsync, payload);
    if (addCouponResponse.status === true) {
      yield put(addCouponSuccess(addCouponResponse.data));
    }
  } catch (error) {
    yield put(addCouponFailed(error.response.data));
  }
}

function* deleteCoupon({ payload }) {
  try {
    const deleteCouponResponse = yield call(deleteCouponAsync, payload);
    if (deleteCouponResponse.status === true) {
      yield put(deleteCouponSuccess(deleteCouponResponse.data));
    }
  } catch (error) {
    yield put(deleteCouponFailed(error.response.data));
  }
}

function* updateCoupon({ payload }) {
  try {
    const updateCouponResponse = yield call(updateCouponAsync, payload);
    if (updateCouponResponse.status === true) {
      yield put(updateCouponSuccess(updateCouponResponse.data));
    }
  } catch (error) {
    yield put(updateCouponFailed(error.response.data));
  }
}

export function* watchGetCoupons() {
  yield takeEvery(GET_COUPONS, getCoupons);
}

export function* watchAddCoupon() {
  yield takeEvery(ADD_COUPON, addCoupon);
}

export function* watchDeleteCoupon() {
  yield takeEvery(DELETE_COUPON, deleteCoupon);
}

export function* watchUpdateCoupon() {
  yield takeEvery(UPDATE_COUPON, updateCoupon);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCoupons),
    fork(watchAddCoupon),
    fork(watchDeleteCoupon),
    fork(watchUpdateCoupon),
  ]);
}
