import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import {
  GET_CARRIERS,
  ADD_CARRIER,
  DELETE_CARRIER,
  UPDATE_CARRIER,
} from "../actions";
import { API_ENDPOINT } from "../../constant";
import {
  getCarriersSuccess,
  getCarriersFailed,
  addCarrierSuccess,
  addCarrierFailed,
  deleteCarrierSuccess,
  deleteCarrierFailed,
  updateCarrierSuccess,
  updateCarrierFailed,
} from "./actions";

const getCarriersAsync = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_ENDPOINT}/carrier/default`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const addCarrierAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_ENDPOINT}/carrier`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const deleteCarrierAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_ENDPOINT}/carrier?id=${payload.id}`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const updateCarrierAsync = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_ENDPOINT}/carrier`, payload)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function* getCarriers() {
  try {
    const getCarriersResponse = yield call(getCarriersAsync);
    if (getCarriersResponse.status === true) {
      yield put(getCarriersSuccess(getCarriersResponse.data));
    }
  } catch (error) {
    yield put(getCarriersFailed(error.response.data));
  }
}

function* addCarrier({ payload }) {
  try {
    const addCarrierResponse = yield call(addCarrierAsync, payload);
    if (addCarrierResponse.status === true) {
      yield put(addCarrierSuccess(addCarrierResponse.data));
    }
  } catch (error) {
    yield put(addCarrierFailed(error.response.data));
  }
}

function* deleteCarrier({ payload }) {
  try {
    const deleteCarrierResponse = yield call(deleteCarrierAsync, payload);
    if (deleteCarrierResponse.status === true) {
      yield put(deleteCarrierSuccess(deleteCarrierResponse.data));
    }
  } catch (error) {
    yield put(deleteCarrierFailed(error.response.data));
  }
}

function* updateCarrier({ payload }) {
  try {
    const updateCarrierResponse = yield call(updateCarrierAsync, payload);
    if (updateCarrierResponse.status === true) {
      yield put(updateCarrierSuccess(updateCarrierResponse.data));
    }
  } catch (error) {
    yield put(updateCarrierFailed(error.response.data));
  }
}

export function* watchGetCarriers() {
  yield takeEvery(GET_CARRIERS, getCarriers);
}

export function* watchAddCarrier() {
  yield takeEvery(ADD_CARRIER, addCarrier);
}

export function* watchDeleteCarrier() {
  yield takeEvery(DELETE_CARRIER, deleteCarrier);
}

export function* watchUpdateCarrier() {
  yield takeEvery(UPDATE_CARRIER, updateCarrier);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCarriers),
    fork(watchAddCarrier),
    fork(watchDeleteCarrier),
    fork(watchUpdateCarrier),
  ]);
}
