import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import userSagas from "./usermanagement/saga";
import dashboardSagas from "./dashboard/saga";
import snnSagas from "./snn/saga";
import subscriptionSagas from "./subscription/saga";
import couponSagas from "./coupon/saga";
import transactionSagas from "./transactions/saga";
import commonSagas from "./common/saga";
import carrierSagas from "./carrier/saga";
import blogSagas from "./blog/saga"

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    dashboardSagas(),
    snnSagas(),
    subscriptionSagas(),
    couponSagas(),
    transactionSagas(),
    commonSagas(),
    carrierSagas(),
    blogSagas()
  ]);
}
